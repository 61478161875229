
import { defineComponent } from 'vue';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
// import { restaurantOutline, storefrontOutline, logOutOutline, closeOutline, peopleOutline, starOutline, logoInstagram, documentTextOutline, chevronForwardOutline } from 'ionicons/icons';
import LoginForm from '@/components/LoginForm.vue';
import store from '@/store';
// import router from '@/router';

export default defineComponent({
  name: 'Login',
  components: { LoginForm, IonHeader, IonToolbar, IonTitle, IonContent, IonPage },
  computed: {
    loginState: () => {
      return store.getters.getLoginState;
    },
    currentUserInfo() {
      return store.getters.getUserInfo;
    },
  },
  // data() {
  //   return {
  //     router,
  //   };
  // },
});
